body {
  background-color: #004507;
  font-family: 'JetBrains Mono', monospace;
  color: white;
}
.hex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px;
}
.hex {
  display: flex;
  justify-content: center;
  position: relative;
  /*background-color: #bd9e12;*/
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
@media only screen and (max-width: 400px) {
  .hex {
    width: 100px;
    height: 106px; 
  }
}

@media only screen and (min-width: 400px) {
  .hex {
    width: 120px;
    height: 132px; 
  }
}
@media only screen and (min-width: 800px) {
  .hex {
    width: 240px;
    height: 265px; 
  }
}

.inner {
  transform: scale(0.9,0.9);
  /*background-color: #6C3EA1;*/
}
.player-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: bold;
  z-index: 1;
  margin-top: -20px;
}

.player-info > * {
  margin: 0;
}

.player-image {
  position: absolute;
  height: 90%;
  bottom: 0;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.title {
  color: white;
}

.banner {
  background-color: #6C3EA1;
  width: 100%;
  margin: 10px;
  border-bottom: solid;
  border-color: #bd9e12;
  border-radius: 30px;
}

.footer {
  width: 100%;
}

.table {
  --bs-table-color: inherit;
  --bs-table-bg: transparent;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}